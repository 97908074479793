export const GEO_PHONES = {
    'ph': {
        regex: /^(63936|63937|63935|63926|63917|63916|63915|63906|63905|63922|63923|63932|63933|63934|63963)[0-9]{7}$/,
        placeholder: '+63 (__) ___-___-__',
        mask: '+63 (##) ###-###-##',
    },
    'lk':
        {
            regex: /^(94)[0-9]{9}$/,
            placeholder: '+94(__) ___-__-__',
            mask: '+94(##) ###-##-##',
        },
    'ua':
        {
            regex: /^(380)[0-9]{9}$/,
            placeholder: '+380 (__) ___-__-__',
            mask: '+380 (##) ###-##-##',
        },
    'kz':
        {
            regex: /^(7)[0-9]{10}$/,
            placeholder: '+7 (___) ___-__-__',
            mask: '+7 (###) ###-##-##',
        },
}
